import { Interpolation, Theme } from "@emotion/react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useRef, useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import SectionHeading from "../SectionHeading/SectionHeading"
import ExternalTextLink from "../Link/ExternalTextLink"
import { DrivingDirectionsProps } from "./DirectionsAddress.d"
import Accordion from "../Accordion/Accordion"
import TranslateClient from "../../clients/TranslateClient"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")
import { LanguageContext } from "../../context/Language"
import DrivingDirectionItem from "./DrivingDirectionItem"

const DrivingDirections: React.FC<DrivingDirectionsProps> = ({
  headline,
  description,
  directions,
}) => {
  const [text, setText] = useState({ headline, description })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ headline, description })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([headline, description])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <article css={[tw`mt-18 max-w-2xl sm:p-4 mx-8 md:mx-auto lg:max-w-4xl`]}>
      <SectionHeading>{text?.headline}</SectionHeading>
      <p
        css={[tw`py-8 border-b border-lightGray`]}
        dangerouslySetInnerHTML={{ __html: text?.description }}
      />
      {directions?.map((item, index) => (
        <Accordion index={index} key={index} title={item.title}>
          <DrivingDirectionItem
            description={item.description}
            mapLink={item.mapLink}
            mapImage={item.mapImage}
          />
        </Accordion>
      ))}
    </article>
  )
}

export default DrivingDirections
