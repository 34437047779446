import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import Button from "../Button/Button"
import { DirectionsAddressProps } from "./DirectionsAddress.d"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"

const DirectionsAddress: React.FC<DirectionsAddressProps> = ({
  title,
  address,
  googleMapsLink,
}) => {
  const [text, setText] = useState({ title })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ title })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([title])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])
  return (
    <article
      css={[tw`px-8 md:px-0 bg-gradient-to-b from-[#96e3ff38] to-white text-center`]}
    >
      <h1 css={[tw`text-3xl md:text-4xl pt-20 font-extrabold`]}>
        {text?.title}
      </h1>
      <p
        css={[tw`mt-5`]}
        dangerouslySetInnerHTML={{ __html: address }}
      />
      <Button
        styles={[tw`mt-8 mx-auto`]}
        link={googleMapsLink?.url}
        text={googleMapsLink?.title}
      />
    </article>
  )
}

export default DirectionsAddress
