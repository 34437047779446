import { getImage } from "gatsby-plugin-image"
import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import { ExternalTextLink } from "../Link"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"
import { Image } from "../Image"
import { ImageSharp } from "../../templates/templates"

export type DrivingDirectionItemProps = {
  description?: string
  mapLink?: {
    target: string
    title: string
    url: string
  }
  mapImage?: ImageSharp
}

const DrivingDirectionItem: React.FC<DrivingDirectionItemProps> = ({
  description,
  mapLink,
  mapImage,
}) => {
  const [text, setText] = useState({ description })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ description })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([description])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])
  return (
    <div css={[tw`gap-8 mb-4 flex flex-col md:flex-row-reverse`]}>
      <div css={[tw`flex flex-col gap-5 justify-center flex-1`]}>
        <p
          dangerouslySetInnerHTML={{ __html: text?.description }}
          css={[tw`md:mb-4`]}
        />
        <ExternalTextLink
          target={mapLink?.target}
          title={mapLink?.title}
          url={mapLink?.url}
        />
      </div>
      <Image
        src={getImage(mapImage?.localFile?.childImageSharp?.gatsbyImageData)}
        publicURL={mapImage?.localFile?.publicURL}
        alt=""
        css={[tw`mt-0 md:h-60 md:w-60`]}
        role="presentation"
      />
    </div>
  )
}

export default DrivingDirectionItem
