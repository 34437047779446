import { graphql, PageProps } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import DirectionsContact from "../../components/Directions/DirectionsContact"
import tw from "twin.macro"
import DirectionsAddress from "../../components/Directions/DirectionsAddress"
import DrivingDirections from "../../components/Directions/DrivingDirections"
import { SEO } from "../../components/SEO"
import { Image } from "../../components/Image"

const Direction: React.FC<PageProps<any>> = ({ data, location }) => {
  const { title, featuredImage, template } = data.wpPage
  const { addressSection, drivingFromSection, directionsContactSection } =
    template.directionsToBHMPage

  return (
    <div className="page-content">
      <SEO url={location.href} title={title} />
      <section>
        <Image
          src={getImage(
            featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
          )}
          publicURL={featuredImage?.node?.localFile?.publicURL}
          alt=""
          role="presentation"
          css={[tw`h-80`]}
          objectFit="cover"
          objectPosition="60% 90%"
        />
        <DirectionsAddress title={title} {...addressSection} />
        <DrivingDirections {...drivingFromSection} />
        <DirectionsContact {...directionsContactSection} />
      </section>
    </div>
  )
}

export default Direction

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      featuredImage {
        node {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      template {
        ... on WpTemplate_Directions {
          templateName
          directionsToBHMPage {
            addressSection {
              address
              googleMapsLink {
                target
                title
                url
              }
            }
            drivingFromSection {
              description
              directions {
                description
                mapImage {
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                mapLink {
                  target
                  title
                  url
                }
                title
              }
              headline
            }
            directionsContactSection {
              contactLink {
                target
                url
                title
              }
              description
              image {
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              title
            }
          }
        }
      }
    }
  }
`
