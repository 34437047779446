import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import { getImage } from "gatsby-plugin-image"
import Button from "../Button/Button"
import { DirectionsContactProps } from "./DirectionsAddress.d"
import TranslateClient from "../../clients/TranslateClient"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")
import { LanguageContext } from "../../context/Language"
import { Image } from "../Image"

const DirectionsContact: React.FC<DirectionsContactProps> = ({
  contactLink,
  description,
  title,
  image,
}) => {
  const imageSrc = getImage(image?.localFile?.childImageSharp?.gatsbyImageData)
  const [text, setText] = useState({ title, description })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ title, description })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([title, description])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])
  return (
    <article
      css={[
        tw`max-w-4xl flex flex-col justify-evenly md:flex-row mt-24 mb-20 md:mx-auto gap-8 md:gap-32`,
      ]}
    >
      <Image src={imageSrc} publicURL={image?.localFile?.publicURL} alt="" role="presentation" />
      <div css={[tw`flex flex-col justify-center items-start mx-8 md:mx-0`]}>
        <h2
          dangerouslySetInnerHTML={{ __html: text?.title }}
          css={[tw`text-3xl md:text-4xl mb-4 font-bold`]}
        />
        <p dangerouslySetInnerHTML={{ __html: text?.description }} />
        <Button
          text={contactLink.title}
          link={contactLink.url}
          target={contactLink.target}
          styles={tw`h-12 mt-12 mx-auto md:mx-0`}
        />
      </div>
    </article>
  )
}

export default DirectionsContact
